<template>
  <section class="ms_info" v-if="membershipIdList.length <= 0">
    <h3><span>THE PARNAS MEMBERSHIP</span></h3>
    <div class="no_mem">
      <p><span>{{ memberName }}</span>님의 더파르나스 멤버십이 미등록 상태입니다.</p>
      <p>가입된 멤버십이 있을 경우 혜택이 공개됩니다.<br>
        <br>
        <span style="font-size: 13px; color: darkgray;">
                          문의 사항 <br/>
                          더파르나스팀 02-559-7645
                          <br>
                          theparnas@parnas.co.kr
                        </span>
      </p>
    </div>
  </section>
  <!--    :autoHeight="true"-->
  <!--    :resizeObserver="true"-->
  <swiper @slideChange="onSlideChange"
          :initialSlide="initialSlide"
          :auto-height="true"
          :height="height"
          :navigation="true"
          :modules="modules"
          style="word-break: keep-all"
          class="mySwiper mySwiper4"
          v-else>

    <!--더파르나스 스마트 멤버십-->
    <swiper-slide
        v-if="
            membershipIdList.includes(4) || membershipIdList.includes(5) ||
            membershipIdList.includes(32) || membershipIdList.includes(33) ||
            membershipIdList.includes(52) || membershipIdList.includes(53) ||
            membershipIdList.includes(54) || membershipIdList.includes(54) ||
            membershipIdList.includes(56) || membershipIdList.includes(57)

        ">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>SMART MEMBERSHIP</span></p>-->
        <p>더파르나스 스마트 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 24회</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 <br>레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>클래식 룸 무료 숙박권 1매<br/>(그랜드 인터컨티넨탈 서울 파르나스 또는 인터컨티넨탈 서울 코엑스)</li>
          <li>레스토랑 5만원 이용권 5매</li>
          <li>하우스 와인 교환권 1매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!--더파르나스 골드 멤버십-->
    <swiper-slide v-if="
            membershipIdList.includes(6) || membershipIdList.includes(7) ||
            membershipIdList.includes(30) || membershipIdList.includes(31) ||
             membershipIdList.includes(52) || membershipIdList.includes(53) ||
            membershipIdList.includes(54) || membershipIdList.includes(54) ||
            membershipIdList.includes(56) || membershipIdList.includes(57)
         ">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>GOLD MEMBERSHIP</span></p>-->
        <p>더파르나스 골드 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 48회</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 <br>레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>주니어 스위트(그랜드 인터컨티넨탈 서울 파르나스) 또는<br/>클래식 룸(인터컨티넨탈 서울 코엑스) 무료 숙박권 1매 (2인 조식 포함)</li>
          <li>레스토랑 10만원 이용권 5매</li>
          <li>하우스 케이크 교환권 1매</li>
          <li>하우스 와인 교환권 1매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!--더파르나스 플래티넘 멤버십-->
    <swiper-slide v-if="
            membershipIdList.includes(10) || membershipIdList.includes(11) ||
            membershipIdList.includes(22) || membershipIdList.includes(28) ||
            membershipIdList.includes(29) ||
             membershipIdList.includes(52) || membershipIdList.includes(53) ||
            membershipIdList.includes(54) || membershipIdList.includes(54) ||
            membershipIdList.includes(56) || membershipIdList.includes(57)
        ">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>PLATINUM MEMBERSHIP</span></p>-->
        <p>더파르나스 플래티넘 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 무제한</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 <br>레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>클럽 인터컨티넨탈 스위트(그랜드 인터컨티넨탈 서울 파르나스) 또는 <br/>
            클럽 클래식 룸(인터컨티넨탈 서울 코엑스) 무료 숙박권 1매
          </li>
          <li>주니어 스위트(그랜드 인터컨티넨탈 서울 파르나스) 또는<br>
            클래식 룸(인터컨티넨탈 서울 코엑스) 무료 숙박권 1매 (2인 조식 포함)
          </li>
          <li>레스토랑 10만원 이용권 8매</li>
          <li>뷔페 1인 이용권(주중, 주말) 2매</li>
          <li>그랜드 델리 부티크 와인샵 5만원 이용권 1매</li>
          <li>Wine Corkage Free 이용권 2매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!--제네시스 스마트 80-->
    <swiper-slide v-if="membershipIdList.includes(34)">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>GENESIS SMART 80 MEMBERSHIP</span></p>-->
        <p>더파르나스 제네시스 스마트 80 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
          <li class="lsty_none">※ 우대요금(BFR)이란? 객실 점유율에 따른 일별 할인 객실 요금입니다.</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 24회</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>클래식 룸 무료 숙박권 1매<br>(그랜드 인터컨티넨탈 서울 파르나스 또는 인터컨티넨탈 서울 코엑스)</li>
          <li>레스토랑 5만원 이용권 4매</li>
          <li>하우스 와인 교환권 1매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!--제네시스 스마트 A-->
    <swiper-slide v-if="membershipIdList.includes(35)">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>GENESIS SMART 70 A MEMBERSHIP</span></p>-->
        <p>더파르나스 제네시스 스마트 A 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
          <li class="lsty_none">※ 우대요금(BFR)이란? 객실 점유율에 따른 일별 할인 객실 요금입니다.</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 24회</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>클래식 룸 무료 숙박권 1매<br>(그랜드 인터컨티넨탈 서울 파르나스 또는 인터컨티넨탈 서울 코엑스)</li>
          <li>레스토랑 3만원 이용권 1매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!--제네시스 스마트 B-->
    <swiper-slide v-if="membershipIdList.includes(36)">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>GENESIS SMART 70 B MEMBERSHIP</span></p>-->
        <p>더파르나스 제네시스 스마트 B 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
          <li class="lsty_none">※ 우대요금(BFR)이란? 객실 점유율에 따른 일별 할인 객실 요금입니다.</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 24회</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>레스토랑 5만원 이용권 4매<br>(매수 제한 없이 사용 가능, 레스토랑 3만원 이용권과는 중복 사용 불가)</li>
          <li>레스토랑 3만원 이용권 1매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!--신한 법인 골드-->
    <swiper-slide v-if="membershipIdList.includes(37)">
      <section class="ms_card_tit">
        <p></p>
        <!--        <p>THE PARNAS <span>SHINHAN CORPORATION GOLD MEMBERSHIP</span></p>-->
        <p>더파르나스 신한 법인 골드 멤버십</p>
      </section>
      <section class="mem_benefit">
        <h4>혜택 상세</h4>
        <h5>객실 혜택<span>상시 객실 할인</span></h5>
        <ul>
          <li>우대 요금(BFR) 기준 그랜드 인터컨티넨탈 서울 15%, 인터컨티넨탈 서울 코엑스 20% 할인</li>
          <li>모든 객실 타입 이용 가능</li>
          <li class="lsty_none">※ 우대요금(BFR)이란? 객실 점유율에 따른 일별 할인 객실 요금입니다.</li>
        </ul>
        <h5>식음료 혜택<span>레스토랑 할인</span></h5>
        <ul>
          <li>레스토랑 할인 횟수 : 48회</li>
          <li>
            <dl>
              <dt>인원에 따른 식사 할인</dt>
              <dd>
                <table>
                  <tr>
                    <th>1~2인 식사 <br/>주문시</th>
                    <td>식사 금액의 20% 할인</td>
                  </tr>
                  <tr>
                    <th>3~7인 식사 <br/>주문시</th>
                    <td>1인분 식사 무료 제공<br/>
                      (1인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>8~19인 식사 <br/>주문시</th>
                    <td>2인분 식사 무료 제공<br/>
                      (2인 평균 식사 요금 할인)
                    </td>
                  </tr>
                  <tr>
                    <th>20~30인 식사 <br/>주문시</th>
                    <td>식사 금액의 10% 할인</td>
                  </tr>
                </table>
                <dl class="mem_benefit_ps">
                  <dt>대상 레스토랑</dt>
                  <dd>웨이루, 하코네, 그랜드 키친, 스카이 라운지, 아시안 라이브, 브래서리</dd>
                </dl>
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>음료 할인</dt>
              <dd>위스키, 꼬냑, 와인을 병으로 주문하실 경우 10% 할인(다른 음료 및 주류 제외)</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>로비 라운지, 로비 바, 그랜드 델리 식음료 할인</dt>
              <dd>식음료 이용 금액의 10% 할인(횟수 제한 없이 이용 가능)</dd>
            </dl>
          </li>
        </ul>
        <h4>바우처 혜택</h4>
        <ul>
          <li>주니어 스위트(그랜드 인터컨티넨탈 서울 파르나스) 또는 클래식 룸(인터컨티넨탈 서울 코엑스) 무료 숙박권 1매 (2인 조식 포함)</li>
          <li>레스토랑 5만원 이용권 6매</li>
        </ul>
        <ul class="ul_sm">
          <li>무료 숙박권의 경우 12월 23, 24, 25, 30, 31일은 이용이 불가합니다.</li>
          <li>상기 모바일 바우처 이용 시, 더파르나스 모바일 앱 > 나의 멤버십 화면 > 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바 랍니다.</li>
        </ul>
        <h4>이용 안내</h4>
        <h5>공통</h5>
        <ul>
          <li>회원 혜택 적용 시 반드시 더파르나스 모바일 멤버십 카드 또는 모바일 바우처를 제시하여 주시기 바랍니다.</li>
          <li>모바일 바우처 이용 시, 더파르나스 모바일 앱 -> 나의 멤버십 화면 -> 바우처 [사용하기] 버튼을 통해 이용 조건들을 반드시 참고해 주시기 바랍니다.</li>
          <li>더파르나스 멤버십은 개인 명의의 회원으로 본인만 사용이 가능하며, 할인 적용 시 본인 확인을 요청드릴 수 있습니다.</li>
          <li>더파르나스 이 외의 상품권, 멤버십, 제휴 및 신용 카드 등 할인 및 혜택은 중복 적용되지 않습니다.</li>
          <li>스페셜 프로모션의 경우, 할인 혜택이 제한될 수 있습니다.</li>
          <li>이용 대상 영업장 및 혜택, 제휴 특전 등 호텔의 사정에 따라 조정될 수 있습니다.</li>
        </ul>
        <h5>식음</h5>
        <ul>
          <li>식사 할인의 경우 소수점 이하의 할인율은 적용되지 않습니다. 또한, 동시에 여러 테이블에서 이용하거나, 인원을 나누어서 할인이 적용되지 않습니다.</li>
          <li>연회 행사 및 룸 서비스 이용 시 레스토랑 할인 혜택이 제공되지 않습니다.</li>
        </ul>
        <h5>객실</h5>
        <ul>
          <li style="word-break: break-all">더파르나스 할인 요금 적용 및 객실 숙박권 이용은 객실예약실을 통하여 가능합니다.<br/>
            (온라인 예약 불가능 / 객실예약실 02-559-7777, reservation@parnas.co.kr)
          </li>
          <li>반드시 숙박권 번호로 사전 예약해주시기 바라며, 체크인 시 숙박권을 제시하셔야 이용이 가능합니다.</li>
          <li>1박에 2개의 객실까지 본인에 한 하여 이용 가능하며, 반드시 사전 예약해주시기 바랍니다.</li>
          <li>객실 할인 및 무료 숙박권은 2인 기준이며 3인 이상 이용 시, 요금이 별도로 추가 부과됩니다.</li>
          <li>객실 무료 숙박권을 통한 객실 예약은 일반 객실 예약과 구분되어 운영되며, 호텔 별로 예약 가능한 객실 수가 제한되어 있습니다.</li>
          <li>객실 할인 및 무료 숙박권은 IHG One Rewards 포인트 적립 및 앰버서더, 로얄 앰버서더 혜택을 포함한 다른 혜택과 중복 적용이 불가합니다.</li>
        </ul>
        <h4>더파르나스 멤버십 약관</h4>
        <ul>
          <li>제1조 회원 가입 자격은 개인에 한하며, 법인은 가입할 수 없습니다.</li>
          <li>제2조 회원 카드 및 바우처의 유효기간은 발급일로부터 1년입니다. (유효기간 연장 불가)</li>
          <li>제3조 회원 카드 및 바우처는 본인 이외에는 사용할 수 없으며 타인에게 대여, 양도 및 담보 제공 등의 목적으로 사용할 수 없습니다.</li>
          <li>제4조 회원카드는 신용카드 용도로 사용할 수 없습니다.</li>
          <li>제5조 회원은 과실로 인한 카드의 분실, 도난, 파손시 발급받은 호텔 더파르나스팀에 즉시 통지하여야 하며, 호텔의 판단에 의하여 재발급합니다. (단, 재발급시 별도의 수수료
            비용 1만원
            및 회원 요청에 의한 특별한 배송은 수취인이 부담하셔야 합니다.)
          </li>
          <li>제6조 호텔이 회원에게 제공한 바우처는 재발급하지 않으며, 현금 또는 다른 상품으로 교환할 수 없습니다. 또한 발급된 바우처는 어떠한 경우에도 기간연장이 적용되지
            않습니다.
          </li>
          <li>제7조 회원 특전은 호텔이 제공하는 기타 다른 할인 혜택 및 바우처 등과 중복 적용되지 않습니다.</li>
          <li>제8조 회원은 호텔 이용 시 반드시 카드를 제시하여야만 해당 특전을 받을 수 있습니다. (회원 카드 미소지 회원의 경우 회원 여부 확인을 위하여 신분증 제시를 요청 할 수
            있습니다.)
          </li>
          <li>제9조 가입취소는 발급일로부터 30일 이내에만 가능하며, 반드시 회원카드를 반납해야 합니다. 단, 1회이상 사용 또는 발급 후 1개월 이상 경과 시 취소 및 타입변경이
            불가합니다.
          </li>
          <li>제10조 호텔은 회원이 다음 각 항에 해당하는 행위를 할 경우 회원자격을 정지 또는 박탈시킬 수 있습니다.
            <br>1. 가입 신청서를 허위로 기재하였을 경우
            <br>2. 멤버십을 부당한 목적으로 사용하였을 경우
            <br>3. 회원이 본 약관을 위반하였을 경우
          </li>
          <li>제11조 회원의 자격정지 또는 박탈의 경우 기 납입한 연회비는 반환되지 않습니다.</li>
          <li>제12조 호텔은 필요한 경우 멤버십 운영 및 회원 특전 등에 대한 내용을 사전 또는 사후 고지를 통해 변경, 추가 또는 취소 등의 결정을 할 수 있습니다. 또한, 호텔은
            제휴업체의
            일방적인 제휴조건 변경이나 영업폐지, 천재지변, 호텔 경영상황의 급변 내지 경영위기 기타 이에 준하는 사유가 발생한 경우 멤버십 운영 및 회원특전 등에 대한 변경, 추가
            또는 취소 등의
            결정을 할 수 있습니다.
          </li>
        </ul>
        <p class="mems_contact">※ 문의 : 더파르나스팀 02-559-7723, 02-559-7660/ theparnas@parnas.co.kr</p>
      </section>
    </swiper-slide>

    <!----------------------------------------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <!--  2023 05 08 파르나스 호텔 제주 이후 추가된 멤버십 혜택 -->
    <!--더파르나스 스마트(통합)-->
    <swiper-slide v-if="membershipIdList.includes(39) || membershipIdList.includes(40)">
      <SmartMembership_2023/>
    </swiper-slide>

    <!--더파르나스 골드(통합)-->
    <swiper-slide v-if="membershipIdList.includes(41) || membershipIdList.includes(42)">
      <GoldMembership_2023/>
    </swiper-slide>

    <!--더파르나스 플래티넘(통합)-->
    <swiper-slide v-if="membershipIdList.includes(43) || membershipIdList.includes(44)">
      <PlatMembership_2023/>
    </swiper-slide>

    <!--더파르나스 제네시스 스마트 80-->
    <swiper-slide v-if="membershipIdList.includes(45)">
      <GenesisSmart_2023/>
    </swiper-slide>

    <!--더파르나스 제네시스 스마트 70 A-->
    <swiper-slide v-if="membershipIdList.includes(46)">
      <GenesisSmartA_2023/>
    </swiper-slide>

    <!--더파르나스 제네시스 스마트 70 B -->
    <swiper-slide v-if="membershipIdList.includes(47)">
      <GenesisSmartB_2023/>
    </swiper-slide>

    <!--신한 법인 골드-->
    <swiper-slide v-if="membershipIdList.includes(48)">
      <ShinhanGold_2023/>
    </swiper-slide>

    <!--양양 스마트 A-->
    <swiper-slide v-if="membershipIdList.includes(49)">
      <InscapeSmartA_2023/>
    </swiper-slide>

    <!--양양 스마트 B-->
    <swiper-slide v-if="membershipIdList.includes(50)">
      <InscapeSmartB_2023/>
    </swiper-slide>

    <!--양양 골드-->
    <swiper-slide v-if="membershipIdList.includes(51)">
      <InscapeGold_2023/>
    </swiper-slide>

    <!----------------------------------------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------------------------------------->
    <!-- 2023 12 27 작업-->
    <!-- 2024 이후 멤버십 추가 목록-->
    <!-- 스마트, 스마트 갱신-->
    <swiper-slide v-if="membershipIdList.includes(75) || membershipIdList.includes(76) ||
                        membershipIdList.includes(63) || membershipIdList.includes(62) ||
                        membershipIdList.includes(61) || membershipIdList.includes(60) ||
                        membershipIdList.includes(59) || membershipIdList.includes(58)">
      <SmartMembership/>
    </swiper-slide>

    <!-- 골드, 골드 갱신-->
    <swiper-slide v-if="membershipIdList.includes(74) || membershipIdList.includes(73) ||
                        membershipIdList.includes(63) || membershipIdList.includes(62) ||
                        membershipIdList.includes(61) || membershipIdList.includes(60) ||
                        membershipIdList.includes(59) || membershipIdList.includes(58)">
      <GoldMembership/>
    </swiper-slide>

    <!-- 플래티넘, 플래티넘 갱신-->
    <swiper-slide v-if="membershipIdList.includes(72) || membershipIdList.includes(71) ||
                        membershipIdList.includes(63) || membershipIdList.includes(62) ||
                        membershipIdList.includes(61) || membershipIdList.includes(60) ||
                        membershipIdList.includes(59) || membershipIdList.includes(58)">
      <PlatinumMembership/>
    </swiper-slide>

    <!-- 제네시스 스마트 80-->
    <swiper-slide v-if="membershipIdList.includes(70)">
      <GenesisSmartMembership/>
    </swiper-slide>

    <!-- 제네시스 스마트 A-->
    <swiper-slide v-if="membershipIdList.includes(69)">
      <GenesisSmartAMembership/>
    </swiper-slide>

    <!--제네시스 스마트 B-->
    <swiper-slide v-if="membershipIdList.includes(68)">
      <GenesisSmartBMembership/>
    </swiper-slide>

    <!--제네시스 EV G80-->
    <swiper-slide v-if="membershipIdList.includes(100)">
      <GenesisEVG80_202408/>
    </swiper-slide>

    <!--신한 법인 골드-->
    <swiper-slide v-if="membershipIdList.includes(67)">
      <ShinhanGoldMembership/>
    </swiper-slide>

    <!--양양 스마트 A-->
    <swiper-slide v-if="membershipIdList.includes(66)">
      <InscapeSmartAMembership/>
    </swiper-slide>

    <!--양양 스마트 B-->
    <swiper-slide v-if="membershipIdList.includes(65)">
      <InscapeSmartBMembership/>
    </swiper-slide>

    <!--양양 스마트 골드-->
    <swiper-slide v-if="membershipIdList.includes(64)">
      <InscapeGoldMembership/>
    </swiper-slide>

    <!--쟈스민 블루 스마트-->
    <swiper-slide v-if="membershipIdList.includes(78)">
      <JarsSmartAMembership/>
    </swiper-slide>

    <!--쟈스민 블랙 -->
    <swiper-slide v-if="membershipIdList.includes(77)">
      <JarsGoldAMembership/>
    </swiper-slide>

    <!----------------------202406이후---------------------->
    <!--쟈스민 블루 전용_더파르나스 스마트 -->
    <swiper-slide v-if="membershipIdList.includes(79)">
      <JarsSmart_202406/>
    </swiper-slide>

    <!--쟈스민 블랙 전용_더파르나스 골드 -->
    <swiper-slide v-if="membershipIdList.includes(80)">
      <JarsGold_202406/>
    </swiper-slide>

    <!--스마트, 스마트 갱신 -->
    <swiper-slide v-if="membershipIdList.includes(81) || membershipIdList.includes(82) ||
                        membershipIdList.includes(94) || membershipIdList.includes(95)">
      <Smart_202406/>
    </swiper-slide>

    <!--골드, 골드 갱신 -->
    <swiper-slide v-if="membershipIdList.includes(83) || membershipIdList.includes(84) ||
                        membershipIdList.includes(96) || membershipIdList.includes(97)">
      <Gold_202406/>
    </swiper-slide>

    <!--플래티넘, 플래티넘 갱신 -->
    <swiper-slide v-if="membershipIdList.includes(85) || membershipIdList.includes(86) ||
                        membershipIdList.includes(98) || membershipIdList.includes(99)">
      <Platinum_202406/>
    </swiper-slide>

    <!--제네시스 스마트 80 -->
    <swiper-slide v-if="membershipIdList.includes(87)">
      <Genesis_202406/>
    </swiper-slide>

    <!--제네시스 스마트 A -->
    <swiper-slide v-if="membershipIdList.includes(88)">
      <GenesisA_202406/>
    </swiper-slide>

    <!--제네시스 스마트 B -->
    <swiper-slide v-if="membershipIdList.includes(89)">
      <GenesisB_202406/>
    </swiper-slide>

    <!--신한 법인 골드 -->
    <swiper-slide v-if="membershipIdList.includes(90)">
      <ShinhanGold_202406/>
    </swiper-slide>

    <!--인스케이프 양양 스마트 A -->
    <swiper-slide v-if="membershipIdList.includes(91)">
      <InscapeSmartA_202406/>
    </swiper-slide>

    <!--인스케이프 양양 스마트 B -->
    <swiper-slide v-if="membershipIdList.includes(92)">
      <InscapeSmartB_202406/>
    </swiper-slide>

    <!--인스케이프 양양 스마트 B -->
    <swiper-slide v-if="membershipIdList.includes(93)">
      <InscapeGold_202406/>
    </swiper-slide>

    <!----------------------------------------------------------------------------------------------------------------->
    <!-- 2024 12 30 작업-->
    <!-- 2025 이후 멤버십 추가 목록-->
    <!-- 스마트, 스마트 갱신 -->
    <swiper-slide v-if="membershipIdList.includes(101) || membershipIdList.includes(102) ||
                        membershipIdList.includes(114) || membershipIdList.includes(115)">
      <Smart_2025/>
    </swiper-slide>

    <!-- 골드, 골드 갱신 -->
    <swiper-slide v-if="membershipIdList.includes(103) || membershipIdList.includes(104) ||
                        membershipIdList.includes(116) || membershipIdList.includes(117)">
      <Gold_2025/>
    </swiper-slide>

    <!-- 플래티넘, 플래티넘 갱신 -->
    <swiper-slide v-if="membershipIdList.includes(105) || membershipIdList.includes(106) ||
                        membershipIdList.includes(118) || membershipIdList.includes(119)">
      <Platinum_2025/>
    </swiper-slide>

    <!-- 제네시스 스마트 80 -->
    <swiper-slide v-if="membershipIdList.includes(107)">
      <Genesis_2025/>
    </swiper-slide>

    <!-- 제네시스 스마트B 70A -->
    <swiper-slide v-if="membershipIdList.includes(108)">
      <GenesisA_2025/>
    </swiper-slide>

    <!-- 제네시스 스마트B 70B -->
    <swiper-slide v-if="membershipIdList.includes(109)">
      <GenesisB_2025/>
    </swiper-slide>

    <!-- 신한 법인 골드 -->
    <swiper-slide v-if="membershipIdList.includes(110)">
      <ShinhanGold_2025/>
    </swiper-slide>

    <!-- 인스케이프 양양 스마트 A -->
    <swiper-slide v-if="membershipIdList.includes(111)">
      <InscapeSmartA_2025/>
    </swiper-slide>

    <!--    인스케이프 양양 스마트 B-->
    <swiper-slide v-if="membershipIdList.includes(112)">
      <InscapeYangyangSmartB_2025/>
    </swiper-slide>

    <!--    인스케이프 양양 골드-->
    <swiper-slide v-if="membershipIdList.includes(113)">
      <InscapeYangyangGold_2025/>
    </swiper-slide>

    <!--    쟈스민 블루 전용_더파르나스 스마트-->
    <swiper-slide v-if="membershipIdList.includes(120)">
      <JarsBlueTheParnasSmart_2025/>
    </swiper-slide>

    <!--    쟈스민 블랙 전용_더파르나스 골드-->
    <swiper-slide v-if="membershipIdList.includes(121)">
      <JarsBlackTheParnasGold_2025/>
    </swiper-slide>

    <!--    쟈스민 전용_더파르나스 스마트-->
    <swiper-slide v-if="membershipIdList.includes(122)">
      <JarsOnlyTheParnasSmart_2025/>
    </swiper-slide>

    <!--    신한더프리미어 스마트-->
    <swiper-slide v-if="membershipIdList.includes(123)">
      <SmartShinhanThePremier_2025/>
    </swiper-slide>

    <!--    제네시스 스마트 EV G80 멤버십-->
    <swiper-slide v-if="membershipIdList.includes(124)">
      <GenesisSmart80evlwb_2025/>
    </swiper-slide>

    <!--    [THE O 전용] 더파르나스 아이초이스 스마트 멤버십 -->
    <swiper-slide v-if="membershipIdList.includes(125)">
      <TheOTheParnasIChoiceSmart_2025/>
    </swiper-slide>

  </Swiper>
</template>
<script>
import {computed, ref} from "vue";
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {useStore} from "vuex";
import SmartMembership from "@/views/main/membershipBenefit/2024/SmartMembership.vue";
import GoldMembership from "@/views/main/membershipBenefit/2024/GoldMembership.vue";
import PlatinumMembership from "@/views/main/membershipBenefit/2024/PlatinumMembership.vue";
import GenesisSmartMembership from "@/views/main/membershipBenefit/2024/GenesisSmartMembership.vue";
import GenesisSmartAMembership from "@/views/main/membershipBenefit/2024/GenesisSmartAMembership.vue";
import GenesisSmartBMembership from "@/views/main/membershipBenefit/2024/GenesisSmartBMembership.vue";
import ShinhanGoldMembership from "@/views/main/membershipBenefit/2024/ShinhanGoldMembership.vue";
import InscapeSmartAMembership from "@/views/main/membershipBenefit/2024/InscapeSmartAMembership.vue";
import InscapeSmartBMembership from "@/views/main/membershipBenefit/2024/InscapeSmartBMembership.vue";
import InscapeGoldMembership from "@/views/main/membershipBenefit/2024/InscapeGoldMembership.vue";
import JarsSmartAMembership from "@/views/main/membershipBenefit/2024/JarsSmartAMembership.vue";
import JarsGoldAMembership from "@/views/main/membershipBenefit/2024/JarsGoldAMembership.vue";
import SmartMembership_2023 from "@/views/main/membershipBenefit/2023/Smart_2023.vue";
import GoldMembership_2023 from "@/views/main/membershipBenefit/2023/Gold_2023.vue";
import PlatMembership_2023 from "@/views/main/membershipBenefit/2023/Platinum_2023.vue";
import GenesisSmart_2023 from "@/views/main/membershipBenefit/2023/GenesisSmart_2023.vue";
import GenesisSmartA_2023 from "@/views/main/membershipBenefit/2023/GenesisSmartA_2023.vue";
import GenesisSmartB_2023 from "@/views/main/membershipBenefit/2023/GenesisSmartB_2023.vue";
import ShinhanGold_2023 from "@/views/main/membershipBenefit/2023/ShinhanGold_2023.vue";
import InscapeSmartA_2023 from "@/views/main/membershipBenefit/2023/InscapeSmartA_2023.vue";
import InscapeSmartB_2023 from "@/views/main/membershipBenefit/2023/InscapeSmartB_2023.vue";
import InscapeGold_2023 from "@/views/main/membershipBenefit/2023/InscapeGold_2023.vue";

import JarsSmart_202406 from "@/views/main/membershipBenefit/202406/JarsSmartAMembership.vue"
import JarsGold_202406 from "@/views/main/membershipBenefit/202406/JarsGoldAMembership.vue"
import Smart_202406 from "@/views/main/membershipBenefit/202406/SmartMembership.vue"
import Gold_202406 from "@/views/main/membershipBenefit/202406/GoldMembership.vue"
import Platinum_202406 from "@/views/main/membershipBenefit/202406/PlatinumMembership.vue";
import Genesis_202406 from "@/views/main/membershipBenefit/202406/GenesisSmart.vue";
import GenesisA_202406 from "@/views/main/membershipBenefit/202406/GenesisSmartA.vue";
import GenesisB_202406 from "@/views/main/membershipBenefit/202406/GenesisSmartB.vue";
import GenesisEVG80_202408 from "@/views/main/membershipBenefit/202408/GenesisEVG80.vue";
import ShinhanGold_202406 from "@/views/main/membershipBenefit/202406/ShinhanGold.vue";
import InscapeSmartA_202406 from "@/views/main/membershipBenefit/202406/InscapeSmartA.vue";
import InscapeSmartB_202406 from "@/views/main/membershipBenefit/202406/InscapeSmartB.vue";
import InscapeGold_202406 from "@/views/main/membershipBenefit/202406/InscapeGold.vue";

// 2025
import Smart_2025 from "@/views/main/membershipBenefit/2025/Smart_2025.vue";
import Gold_2025 from "@/views/main/membershipBenefit/2025/Gold_2025.vue";
import Platinum_2025 from "@/views/main/membershipBenefit/2025/Platinum_2025.vue";
import Genesis_2025 from "@/views/main/membershipBenefit/2025/Genesis_2025.vue";
import GenesisA_2025 from "@/views/main/membershipBenefit/2025/GenesisA_2025.vue";
import GenesisB_2025 from "@/views/main/membershipBenefit/2025/GenesisB_2025.vue";
import ShinhanGold_2025 from "@/views/main/membershipBenefit/2025/ShinhanGold_2025.vue";
import InscapeSmartA_2025 from "@/views/main/membershipBenefit/2025/InscapeSmartA_2025.vue";
import InscapeYangyangSmartB_2025 from "@/views/main/membershipBenefit/2025/InscapeYangyangSmartB_2025.vue";
import InscapeYangyangGold_2025 from "@/views/main/membershipBenefit/2025/InscapeYangyangGold_2025.vue";
import JarsBlueTheParnasSmart_2025 from "@/views/main/membershipBenefit/2025/JarsBlueTheParnasSmart_2025.vue";
import JarsBlackTheParnasGold_2025 from "@/views/main/membershipBenefit/2025/JarsBlackTheParnasGold_2025.vue";
import JarsOnlyTheParnasSmart_2025 from "@/views/main/membershipBenefit/2025/JarsOnlyTheParnasSmart_2025.vue";
import SmartShinhanThePremier_2025 from "@/views/main/membershipBenefit/2025/SmartShinhanThePremier_2025.vue";
import GenesisSmart80evlwb_2025 from "@/views/main/membershipBenefit/2025/GenesisSmart80evlwb_2025.vue";
import TheOTheParnasIChoiceSmart_2025 from "@/views/main/membershipBenefit/2025/TheOTheParnasIChoiceSmart_2025.vue";


export default {
  name: "MembershipBenefit",
  components: {
    TheOTheParnasIChoiceSmart_2025,
    GenesisSmart80evlwb_2025,
    SmartShinhanThePremier_2025,
    JarsOnlyTheParnasSmart_2025,
    JarsBlackTheParnasGold_2025,
    JarsBlueTheParnasSmart_2025,
    InscapeYangyangGold_2025,
    InscapeYangyangSmartB_2025,
    InscapeSmartA_2025,
    ShinhanGold_2025,
    GenesisB_2025,
    GenesisA_2025,
    Genesis_2025,
    Platinum_2025,
    Gold_2025,
    Smart_2025,
    InscapeGold_202406,
    InscapeSmartB_202406,
    InscapeSmartA_202406,
    ShinhanGold_202406,
    GenesisB_202406,
    GenesisA_202406,
    Genesis_202406,
    Platinum_202406,
    Gold_202406,
    Smart_202406,
    JarsSmart_202406,
    JarsGold_202406,
    InscapeGold_2023,
    InscapeSmartB_2023,
    InscapeSmartA_2023,
    ShinhanGold_2023,
    GenesisSmartB_2023,
    GenesisSmartA_2023,
    GenesisSmart_2023,
    GenesisEVG80_202408,
    PlatMembership_2023,
    GoldMembership_2023,
    SmartMembership_2023,
    JarsGoldAMembership,
    JarsSmartAMembership,
    InscapeGoldMembership,
    InscapeSmartBMembership,
    InscapeSmartAMembership,
    ShinhanGoldMembership,
    GenesisSmartBMembership,
    GenesisSmartAMembership,
    GenesisSmartMembership,
    PlatinumMembership,
    GoldMembership,
    SmartMembership,
    Swiper,
    SwiperSlide,
  },
  props: {
    initIndex: {
      type: Number,
    }
  },
  setup(props) {
    const isOpen = ref(true)
    const store = useStore()
    // test: 멤버십 추가/수정 확인용
    const membershipIdList = computed(() => store.getters.getMembershipsForSwiper)
    // const membershipIdList = [125];
    const memberName = computed(() => store.getters.getMemberName)
    const height = ref(0)

    const showMembershipTerms = () => {
      isOpen.value = true
    }
    const initialSlide = ref(props.initIndex)

    const onSlideChange = () => {
      isOpen.value = true
    };

    return {
      height,
      membershipIdList,
      memberName,
      isOpen,
      showMembershipTerms,
      onSlideChange,
      initialSlide,
      modules: [Navigation],
    };
  },
};
</script>

<style scoped>
table {
  text-align: center;
}

.swiper-slide {
  display: initial;
}

.table_exp {
  text-align: center;
}

.table_title {
  width: 35%;
}

.hotel-jeju-except > li {
  font-size: 11px;
  line-height: 16px;
  color: #999999;
}
</style>
